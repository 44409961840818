const tipoPlanoNome = {
  CREDITO_PAGAMETADE: "Paga metade",
  CREDITO_INTEGRAL: "Integral",
};

const TipoSimulacaoEnum = {
  CREDITO_INTEGRAL: "CREDITO_INTEGRAL",
  CREDITO_PAGAMETADE: "CREDITO_PAGAMETADE",
  PARCELA_REDUZIDA: "PARCELA_REDUZIDA",
};

const MYCON = "MYCON";
const MODO_TURBO = "MODO_TURBO";
const PERFORMANCE = "PERFORMANCE";
const MYCOINS = "MYCOINS";
const LIVE = "LIVE";
const ANIVERSARIO = "ANIVERSARIO";
const ANIVERSARIO_FINAL_DE_SEMANA = "ANIVERSARIO_FINAL_DE_SEMANA";
const MES_BLACKFRIDAY = "MES_BLACKFRIDAY";
const ESTICA_HOME = "ESTICA_HOME";
const ESTICA_OFERTA = "ESTICA_OFERTA";

const TipoBotEnum = {
  [MYCON]: {
    textChat1:
      "Oi, eu sou o Mycon! Conte comigo para conquistar com o <b>MELHOR PREÇO do mercado. E com taxas a partir de 9,99% por todo o período.</b>",
    textChat2: "Quer conferir? Simule agora e realize seu sonho:",
  },
  [MODO_TURBO]: {
    textChat1:
      "Você tem <b>até este domingo</b> para aproveitar essa <b>OFERTA TURBO.</b> Conquiste seu sonho com...",
    textChat2:
      "<b>REDUÇÃO DE 50% do valor das parcelas até contemplar,</b> mais <b>50% de desconto na sua 1ª parcela.</b> Ah! E taxa especial, de <b>13,99% por 9,99%.</b> Simule já:",
  },
  [PERFORMANCE]: {
    textChat1:
      "Na MyFriday, você realiza um novo sonho com uma promoção pensada <b>PARA VOCÊ!</b><br>😍🤑",
    textChat2:
      "<b>MAS CORRA!</b> Não perca a chance de conquistar mais pagando bem menos. Simule agora:",
  },
  [MYCOINS]: {
    textChat1:
      "Oi, eu sou o Mycon! Conte comigo para conquistar com o <b>MELHOR PREÇO do mercado. E com taxas a partir de 9,99% por todo o período.</b>",
    textChat2:
      "<b>Ahh</b>, e por estar sendo indicado para o Mycon, você ainda vai ganhar 50% de desconto na sua primeira parcela!",
  },
  [LIVE]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>No mês do meu aniversário é você quem conquista o sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer conferir? Simule agora e comece a construir seu futuro agora mesmo:",
  },
  [ANIVERSARIO]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>No mês do meu aniversário é você quem conquista o sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer conferir? Simule agora e comece a construir seu futuro agora mesmo:",
  },
  [ANIVERSARIO_FINAL_DE_SEMANA]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>No mês do meu aniversário é você quem conquista o sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer conferir? Simule agora e comece a construir seu futuro agora mesmo:",
  },
  [MES_BLACKFRIDAY]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>Novembro é o <b>mês perfeito</b> para tirar seus sonhos do papel. Ainda mais com nossa <b>SUPER OFERTA</b> da MyFriday!",
    textChat2:
      "NÃO PERCA! É a última chance do ano para aproveitar a melhor condição. Simule agora:",
  },
  [ESTICA_HOME]: {
    textChat1:
      "Oi, aqui é o Mycon!<br><br>Escuta essa: a <b>MyFriday foi prorrogada</b>. É a última chance de aproveitar a nossa <b>SUPER OFERTA!</b>",
    textChat2:
      "Seu consórcio ainda mais barato está te esperando aqui! <b>NÃO PERCA A CHANCE</b>. Simule agora:",
  },
  [ESTICA_OFERTA]: {
    textChat1:
      "Oi, aqui é o Mycon!<br><br>Escuta essa: a <b>MyFriday foi prorrogada</b>. É a última chance de aproveitar a nossa <b>SUPER OFERTA!</b>",
    textChat2:
      "Seu consórcio ainda mais barato está te esperando aqui! <b>NÃO PERCA A CHANCE</b>. Simule agora:",
  },
};

const ProdutosEnum = {
  IMOVEL: "IMOVEL",
  CARRO: "CARRO",
  MOTO: "MOTO",
  SERVICO: "SERVICO",
  CAMINHAO: "CAMINHAO",
  MAQUINAS: "MAQUINAS",
};

const ProdutosNomes = {
  IMOVEL: "IMÓVEL",
  CARRO: "CARRO",
  MOTO: "MOTO",
  SERVICO: "SERVIÇO",
  CAMINHAO: "CAMINHÃO",
  MAQUINAS: "MÁQUINAS",
};

const valoresSimulacao = {
  [TipoSimulacaoEnum.CREDITO_INTEGRAL]: {
    [ProdutosEnum.IMOVEL]: { min: 100000, max: 700000 },
    [ProdutosEnum.CARRO]: { min: 30000, max: 400000 },
    [ProdutosEnum.MOTO]: { min: 15000, max: 120000 },
    [ProdutosEnum.SERVICO]: { min: 12000, max: 20000 },
    [ProdutosEnum.CAMINHAO]: { min: 100000, max: 400000 },
    [ProdutosEnum.MAQUINAS]: { min: 100000, max: 400000 },
  },
  [TipoSimulacaoEnum.CREDITO_PAGAMETADE]: {
    [ProdutosEnum.IMOVEL]: { min: 100000, max: 700000 },
    [ProdutosEnum.CARRO]: { min: 30000, max: 400000 },
    [ProdutosEnum.MOTO]: { min: 15000, max: 120000 },
    [ProdutosEnum.SERVICO]: { min: 0, max: 0 },
    [ProdutosEnum.CAMINHAO]: { min: 100000, max: 400000 },
    [ProdutosEnum.MAQUINAS]: { min: 100000, max: 400000 },
  },
};

const CategoriaPagamento = {
  CREDITO_INTEGRAL: {
    IMOVEL: 1,
    CARRO: 2,
    MOTO: 3,
    SERVICO: 4,
    CAMINHAO: 6,
    MAQUINAS: 6,
  },
  CREDITO_PAGAMETADE: {
    IMOVEL: 20,
    CARRO: 21,
    MOTO: 22,
    SERVICO: 0, // verificar qual é o id
    CAMINHAO: 26,
    MAQUINAS: 26,
  },
  PARCELA_REDUZIDA: {
    IMOVEL: 29,
    CARRO: 30,
    MOTO: 31,
    SERVICO: 0, // verificar qual é o id
    CAMINHAO: 32,
    MAQUINAS: 32,
  },
};

export {
  ProdutosEnum,
  tipoPlanoNome,
  TipoSimulacaoEnum,
  valoresSimulacao,
  CategoriaPagamento,
  TipoBotEnum,
  ProdutosNomes,
};
